import React from "react"
import { NavLink } from 'react-router-dom';

class Footer extends React.Component{
    render(){
        return(
            <footer className="py-3 my-4">
                <ul class ="nav justify-content-center border-bottom pb-3 mb-3">
                    {/* <NavLink to={{ pathname: "https://www.facebook.com/lenh.nguyen.77" }} target="_blank"><li className="ms-3"><i className="bi bi-facebook"></i></li></NavLink> */}
                    <NavLink to={{ pathname: "https://github.com/nguyenlenh92" }} target="_blank"><li className="ms-3"><i className="bi bi-github"></i></li></NavLink>
                    <NavLink to={{ pathname: "https://www.linkedin.com/in/nguyenlenh92/" }} target="_blank"><li className="ms-3"><i className="bi bi-linkedin"></i></li></NavLink>
                </ul>
                    <p className="text-center text-muted personal-info">Lenh Nguyen</p>
                    <p className="text-center text-muted personal-info">nguyenlenh92@gmail.com</p>
                    <p className="text-center text-muted personal-info">+1 (443) 946-6336</p>
            </footer>
        )
    }
}
export default Footer;