import React, { Component } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";


export default class SimpleCarousel extends Component {

  getImagesContent = () => {
    let content = []
    for (const image in this.props.images){
      content.push(this.props.images[image]);
    }
    return content;
  }

  render(){
    return(
      <div>
        <Carousel showThumbs={false} autoPlay>
          {this.getImagesContent().map(image => (
            <div>
              <img src={image} alt=""></img>
            </div>
          ))}
        </Carousel>
      </div>
    )
  };
}
