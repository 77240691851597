import React, { useEffect } from 'react'
import { Switch, Route } from "react-router-dom";
import AboutPage from '../pages/AboutPage';
import ProjectsPage from "../pages/ProjectsPage";
import ContactPage from "../pages/ContactPage";
import ReactGA from 'react-ga'

const TRACKING_ID = "UA-215681516-1"
ReactGA.initialize(TRACKING_ID);

export default function Content() {
	useEffect(() => {
		ReactGA.pageview(window.location.pathname + window.location.search);
	}, []);

	return(
		<Switch>
			<Route exact path="/" component={AboutPage}></Route>
			<Route exact path="/projects" component={ProjectsPage}></Route>
			<Route exact path="/contact" component={ContactPage}></Route>
		</Switch>
	)
}
