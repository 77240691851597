import React, { Component } from 'react'
import Project from "../components/Project"
import "../static/Projects.css"
export default class ProjectsPage extends Component {

    render() {

        const projectsJSON = require("../data/projects.json");

        return (
            
            <div className="project-container">
                { projectsJSON.Projects.map((project, index) => 
                <Project key={index} 
                className="project-item" 
                title={project.title} 
                intro={project.intro} 
                description={project.description} 
                images={project.images} 
                thumbnail={project.thumbnail}
                github={project.github}
                demo={project.demo}
                
                /> )}
            </div>
        )
    }
}
