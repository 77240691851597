
import React, { Component } from 'react'
import '../index.css';
import '../static/About.css';

export default class AboutPage extends Component {
    render() {
        return (
            <div className="about-container">
                    <div className="profile-picture-container">
                        <img src="images/profile-picture.png" alt="Personal Portrait"/>
                    </div>

                <div className="background-information screen-size">
                    <div className="quick-introduction">
                        <p> I am currently a senior student at University of Maryland - Baltimore County, with plan to graduate
                            by Spring of 2022. As an aspiring front-end software developer, I seek to gain professional exposure
                            and have an opportunity to apply my development skills to business needs, using cutting-edge
                            technology, industry-standard software, and proven engineering frameworks.
                        </p>
                    </div>

                    <ul>
                        <li>Concentration in computer science with broad knowledge of software engineering and high-level programming concepts.</li>

                        <li>Worked in group setting to develop useful and meaningful applications as a developer lead.</li>

                        <li>Thorough understanding of Agile and Software Development Life Cycle. Have first-hand experience with SCRUM using JIRA.
                            Worked as a Product Owner and SCRUM member to maintain, develop, and complete backlog.</li>

                        <li>Intermediate understanding of Web concepts, such as HTTP Requests, REST APIs, Async Request, etc.</li>

                        <li>Basic understanding of database design and development. Worked with SQLite, MariaDB, phpMyAdmin, and SQL itself.</li>

                        <li>Basic familiarity with AWS, and its components such as Lambda function, API Gateway, Amplify, Simple Storage Service, Simple Email Service, EC2, and Route 53.</li>

                        <li>Passionate for programming, engineering, and innovation. Highly motivated and eager to learn new things.</li>
                    </ul>

                </div>

            </div>
        )
    }
}
