import React from "react";
import { NavLink } from 'react-router-dom';



class Header extends React.Component{
    redirectToResume = () => {
        // window.open('/personal-resources/Lenh-Nguyen-resume.pdf', '_blank');
    }

    redirectToTranscript = () => {
        
        // window.open('/personal-resources/Lenh-Nguyen-unofficial-transcript.pdf', '_blank');
    }

    render(){
        return(
            <header className="py-3 mb-3 header screen-size">
                <div className="container-fluid d-grid gap-3 align-items-center" style={{gridTemplateColumns: "6fr 0fr"}}>
                    <NavLink exact to="/" className="nav-link"><span className="my-name">Lenh Nguyen</span> <br/> <span class="my-title">Software Engineer</span></NavLink>


                    <div className="d-flex align-items-center">
                        <NavLink exact to="/projects" className="nav-link">Projects</NavLink>
                        <NavLink exact to="/contact" className="nav-link">Contact</NavLink>
                        <a href="https://publicresourcesbucket.s3.us-east-2.amazonaws.com/Lenh+Nguyen_Resume_02-25-2022.pdf" className="nav-link pointer-on-hover" target="_blank" rel="noreferrer">Resume</a>
                        {/* <a href="https://publicresourcesbucket.s3.us-east-2.amazonaws.com/Lenh+Nguyen_unofficial+transcript_11-8-21.pdf" className="nav-link pointer-on-hover" target="_blank">Transcript</a> */}
                    </div>
                </div>
            </header>
        )
    }
}
export default Header;