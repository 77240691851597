import React from "react";
import Header from "./layout/Header"
import Content from "./layout/Content"
import Footer from "./layout/Footer"
import { BrowserRouter } from 'react-router-dom';


class App extends React.Component {
	
	render() {
		return(
			<BrowserRouter>
			<div>
				<div className="header-container">
					<Header />
				</div>
				<div className="content">
					<Content />
				</div>
				
				<div className="footer">
					<Footer />
				</div>
			</div>
			</BrowserRouter>
			)
		}
	}
	

	
export default App;
	