import React, { Component } from "react"
import Icon from '@mdi/react'
import { mdiClose } from '@mdi/js'
import SimpleCarousel from "../components/Carousel";

import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";

import Card from "react-bootstrap/Card"

export default class Project extends Component {
    constructor(props){
        super(props);
        this.state = {
            isOpen: false
        };

        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
    }

    showModal(){
        this.setState({isOpen : true});
        console.log(this.state.isOpen);
    }

    hideModal(){
        this.setState({isOpen : false});
    }

    render(){

        return(
            <Card style={{ width: '30rem', margin: '1rem'}}>
                <Card.Img src={this.props.thumbnail} alt=""></Card.Img>
                <Card.Body>
                    <Card.Title style={{ textAlign: "center" }}>{this.props.title}</Card.Title>
                    <Card.Text>{this.props.intro}</Card.Text>
                    <button className="btn btn-primary" onClick={this.showModal}>Learn more</button>

                    <Modal centered size="lg" backdrop="static" show={this.state.isOpen} onHide={this.hideModal}>
                        <ModalHeader>
                            {/* <Card> */}
                                <SimpleCarousel images={this.props.images}/>
                            {/* </Card> */}
                        </ModalHeader>
                        <ModalBody>
                            <div className="d-flex gap-2">
                                {this.props.description}
                                {this.props.github && <a href={this.props.github}>GitHub</a>}
                                {this.props.demo && <a href={this.props.demo}>Demo</a>}
                            </div>
                        </ModalBody>
                        <ModalFooter><button className="exit-btn" onClick={this.hideModal}><Icon path={mdiClose} size={1} /></button></ModalFooter>
                    </Modal>
                </Card.Body>
            </Card>
        )
    }
}